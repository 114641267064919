<template>
  <div class="content">
    <el-card class="box-card">
      <div class="Untreated_top" slot="header">
        <span class="top_title">{{$t('工单记录')}}</span>
        <div>
          <!-- <el-button type="primary" v-if="user_role['work_order/add']" @click='dialogForm' size="small">生成工单</el-button> -->
          <el-button type="primary" size="small" @click="getUserList(Page.page,1)">{{$t('导出EXCEL')}}</el-button>
        </div>
      </div>
      <!-- 搜索栏 -->
      <div class="search">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-input :placeholder="$t('请输入查询关键字')" clearable v-model="search.name" class="input-with-select"></el-input>
          </el-col>
          <el-col :span="6">
            <el-select v-model="search.oc_id" clearable :placeholder="$t('请选择客户ID')">
              <el-option v-for="item in ocList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!-- 服务类型 -->
          <el-col :span="6">
            <el-select v-model="search.service_type" clearable :placeholder="$t('请选择服务类型')">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-select v-model="search.status" clearable :placeholder="$t('请选择订单流程状态')">
              <el-option v-for="item in statuList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-select v-model="search.is_deactivate" clearable :placeholder="$t('请选择工单状态')">
              <el-option v-for="item in deactList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-select v-model="search.is_evaluate" clearable :placeholder="$t('请选择评论状态')">
              <el-option v-for="item in commentList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="12">
            <el-date-picker v-model="sreachDate" type="datetimerange" align="right" range-separator="-"
              :start-placeholder="$t('开始日期')" :end-placeholder="$t('结束日期')" value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-col>
          <el-col :span="6">
            <el-button icon="el-icon-search" @click="getUserList(1)"></el-button>
          </el-col>
        </el-row>
      </div>
      <el-table :data="UntreatedData" height="500" style="width: 100%" border>
        <el-table-column :label="$t('ID')" prop="id" fixed="left" width="100" align="center" header-align="left">
        </el-table-column>
        <el-table-column :label="$t('工单号')" prop="wordernum" width="200">
        </el-table-column>
        <el-table-column :label="$t('工单状态')" prop="status_txt" width="150">
        </el-table-column>
        <el-table-column :label="$t('处理方式')" prop="disposition_txt" width="150">
        </el-table-column>
       <!-- <el-table-column label="客户ID" prop="oc_id" width="100">
        </el-table-column> -->
        <el-table-column :label="$t('客户名称')" prop="kehu_name" width="200">
        </el-table-column>
        <el-table-column :label="$t('联系人')" prop="contacts" width="160">
        </el-table-column>
        <el-table-column :label="$t('联系电话')" prop="contact_number" width="200">
        </el-table-column>
        <el-table-column :label="$t('质保类型')" prop="warranty_type_txt" width="160">
        </el-table-column>
        <el-table-column :label="$t('服务类型')" prop="service_type_txt" width="150">
        </el-table-column>
        <el-table-column :label="$t('服务内容')" prop="service_content" width="300">
        </el-table-column>
        <el-table-column :label="$t('报单时间')" prop="declaration_time" width="200">
        </el-table-column>
        <el-table-column :label="$t('客户等待时间')" width="200">
          <template slot-scope="scope">
            <span>{{`${scope.row.ddtime} ${ $t('天')}`}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('总评分')" prop="fraction_total" width="100">
        </el-table-column>
        <el-table-column fixed="right" :label="$t('操作')" align="center" header-align="left" width="250">
          <template slot-scope="scope">
            <!-- 工单流程按钮 -->
            <el-button type="text" size="small"
              v-for="item in BtnList"
              :key="item.status"
              v-if="scope.row.status===item.status&&user_role[item.user_role]"
              @click="process(scope)">
              {{item.value}}
            </el-button>
            <!-- /工单流程按钮 -->
            <el-button type="text" size="small" v-if="user_role['work_order/detail']" @click="getDetail(scope)">{{$t('查看详情')}}</el-button>
            <el-button type="text" size="small" v-if="scope.row.status!==5&&user_role['work_order/edit']" @click='dialogForm(scope,true)'>{{$t('修改')}}</el-button>
            <el-button type="text" size="small" v-if="user_role['work_order/delete']" @click="delRole(scope)">{{$t('删除')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="Page.page"
        :page-size="Page.pagesize" layout="total, prev, pager, next, jumper" :total="Page.total"
        style="text-align: center;">
      </el-pagination>
    </el-card>
    <el-dialog :title="$t('生成工单')" :visible.sync="addAdmin" width="80%">
      <el-row :gutter="20">
        <el-form :model="addform" :label-position="getLable" label-width="100px" ref="addform">
          <!-- <el-col :span="12">
            <el-form-item label="预约记录ID" prop="os_id">
              <el-input v-model="addform.os_id" placeholder="请输入预约ID" clearable></el-input>
            </el-form-item>
          </el-col> --><!-- /预约记录ID -->
          <el-col :span="12">
            <el-form-item :label="$t('客户名称')" required prop="oc_id"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-select v-model="addform.oc_id" @change="getInfoID(addform.oc_id)" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in ocList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /客户ID -->
          <el-col :span="12">
            <el-form-item :label="$t('服务类型')" required prop="service_type"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-select v-model="addform.service_type" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /服务类型 -->
          <el-col :span="12">
            <el-form-item :label="$t('联系人')" required prop="contacts"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="addform.contacts" clearable></el-input>
            </el-form-item>
          </el-col><!-- /联系人 -->
          <el-col :span="12">
            <el-form-item :label="$t('联系电话')" required prop="contact_number"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="addform.contact_number" clearable></el-input>
            </el-form-item>
          </el-col><!-- /区域联系电话 -->
          <el-col :span="12">
            <el-form-item :label="$t('服务内容')" required prop="service_content"
              :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="addform.service_content" clearable></el-input>
            </el-form-item>
          </el-col><!-- /服务内容 -->
          <el-col :span="12">
            <el-form-item :label="$t('归属区域')" prop="or_id">
              <el-select v-model="addform.or_id" @change="getOEid(addform.or_id)" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in regionList" :key="item.id" :label="item.rname" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /区域id -->
          <el-col :span="12">
            <el-form-item :label="$t('设备id')" prop="oe_id">
              <el-select v-model="addform.oe_id" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in eqList" :key="item.id" :label="item.equipment_name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /设备id-->
          <el-col :span="12">
            <el-form-item :label="$t('报单时间')" prop="declaration_time" required
              :rules="[{ required: true, message: $t('不能为空')}]">
              <el-date-picker v-model="addform.declaration_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                :placeholder="$t('选择日期时间')">
              </el-date-picker>
            </el-form-item>
          </el-col><!-- /报单时间-->
          <el-col :span="12">
            <el-form-item :label="$t('工单接收人')" prop="receive_uid">
              <el-select v-model="addform.receive_uid" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in roleList" :key="item.id" :label="item.username" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /工单接收人-->
          <el-col :span="12">
            <el-form-item :label="$t('工单协助人')" prop="assist_uid">
              <el-select v-model="addform.assist_uid" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in roleList" :key="item.id" :label="item.username" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /工单协助人-->
          <el-col :span="12">
            <el-form-item :label="$t('处理方式')" prop="disposition" required
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-select v-model="addform.disposition" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in disList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /处理方式-->
          <el-col :span="12">
            <el-form-item :label="$t('质保类型')" prop="warranty_type" required
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-select v-model="addform.warranty_type" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in warrList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /质保类型-->
          <el-col :span="24">
            <el-form-item :label="$t('上传图片')" prop="upload_pictures">
              <el-upload action="http://cdshapi.orangeevcharge.com/webapi/file/uploadImg" :http-request="upLoadImg"
                list-type="picture-card" :file-list="addform.imageUrl">
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <img class="el-upload-list__item-thumbnail" :src="file.url">
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible" :modal="false">
                <img width="100%" :src="dialogImageUrl">
              </el-dialog>
            </el-form-item>
          </el-col><!-- /上传图片-->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addAdmin = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="addMin">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog :title="$t('修改信息')" :visible.sync="editAdmin" width="80%" @close="closeEdit">
      <el-row :gutter="20">
        <el-form :model="editform" :label-position="getLable" label-width="100px" ref="editform">
          <!-- <el-col :span="12">
            <el-form-item label="客户名称" required prop="oc_id" :rules="[{ required: true, message: '客户名称不能为空'}]">
              <el-select v-model="editform.oc_id" @change="getInfoID(editform.oc_id)" clearable placeholder="请选择客户">
                <el-option v-for="item in ocList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> --><!-- /客户ID -->
          <el-col :span="12">
            <el-form-item :label="$t('服务类型')" required prop="service_type"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-select v-model="editform.service_type" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /服务类型 -->
          <el-col :span="12">
            <el-form-item :label="$t('联系人')" required prop="contacts"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="editform.contacts" clearable></el-input>
            </el-form-item>
          </el-col><!-- /联系人 -->
          <el-col :span="12">
            <el-form-item :label="$t('联系电话')" required prop="contact_number"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="editform.contact_number" clearable></el-input>
            </el-form-item>
          </el-col><!-- /区域联系电话 -->
          <el-col :span="12">
            <el-form-item :label="$t('服务内容')" required prop="service_content"
              :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="editform.service_content" clearable></el-input>
            </el-form-item>
          </el-col><!-- /服务内容 -->
          <el-col :span="12">
            <el-form-item :label="$t('归属区域')" prop="or_id">
              <el-select v-model="editform.or_id" @change="getOEid(editform.or_id)" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in regionList" :key="item.id" :label="item.rname" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /区域id -->
          <el-col :span="12">
            <el-form-item :label="$t('设备id')" prop="oe_id">
              <el-select v-model="editform.oe_id" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in eqList" :key="item.id" :label="item.equipment_name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /设备id-->
          <el-col :span="12">
            <el-form-item :label="$t('报单时间')" prop="declaration_time" required
              :rules="[{ required: true, message: $t('不能为空')}]">
              <el-date-picker v-model="editform.declaration_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                :placeholder="$t('请选择')">
              </el-date-picker>
            </el-form-item>
          </el-col><!-- /报单时间-->
          <el-col :span="12">
            <el-form-item :label="$t('工单协助人')" prop="assist_uid">
              <el-select v-model="editform.assist_uid" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in roleList" :key="item.id" :label="item.username" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /工单协助人-->
          <el-col :span="12">
            <el-form-item :label="$t('处理方式')" prop="disposition" required
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-select v-model="editform.disposition" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in disList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /处理方式-->
          <el-col :span="12">
            <el-form-item :label="$t('质保类型')" prop="warranty_type" required
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-select v-model="editform.warranty_type" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in warrList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /质保类型-->
          <el-col :span="24">
            <el-form-item :label="$t('上传图片')" prop="upload_pictures">
              <el-upload action="http://cdshapi.orangeevcharge.com/webapi/file/uploadImg" :http-request="upLoadImg"
                list-type="picture-card" :file-list="editform.imageUrl">
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <img class="el-upload-list__item-thumbnail" :src="file.url">
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible" :modal="false">
                <img width="100%" :src="dialogImageUrl">
              </el-dialog>
            </el-form-item>
          </el-col><!-- /上传图片-->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeEdit">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="editMin">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog :title="$t('派工')" :visible.sync="isDispatch">
      <el-form :model="DispatchForm" :label-position="getLable">
        <el-form-item :label="$t('工单接收人')" label-width="100px">
          <el-select v-model="DispatchForm.receive_uid" clearable :placeholder="$t('请选择')">
            <el-option v-for="item in roleList" :key="item.id" :label="item.username" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('工单协助人')" label-width="100px">
          <el-select v-model="DispatchForm.assist_uid" clearable :placeholder="$t('请选择')">
            <el-option v-for="item in roleList" :key="item.id" :label="item.username" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isDispatch = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="subDispatch">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog :title="$t('接单')" :visible.sync="isreceive">
      <el-form :model="receiveForm" :label-position="getLable">
        <el-form-item :label="$t('预计签到时间')" :rules="[{ required: true, message: $t('不能为空')}]">
          <el-date-picker
                v-model="receiveForm.subscribe_time"
                type="datetime"
                clearable
                value-format="yyyy-MM-dd HH:mm:ss"
                :placeholder="$t('选择日期时间')">
              </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('备注信息')" :rules="[{ required: true, message: $t('不能为空')}]">
          <el-input type="textarea" :rows="5" maxlength="300" show-word-limit :placeholder="$t('请填写接单备注')"
            v-model="receiveForm.remarks">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isreceive = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="subReceive">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog :title="$t('签到')" :visible.sync="isSign">
      <el-form :model="SignForm" :label-position="getLable">
        <el-form-item :rules="[{ required: true, message: $t('不能为空')}]">
          <el-input type="textarea" :rows="5" maxlength="300" show-word-limit :placeholder="$t('请填写签到备注')"
            v-model="SignForm.remarks">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isSign = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="subSign">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog :title="$t('完成工单')" :visible.sync="iscomplete" width="80%">
      <el-form :model="completeForm" :label-position="getLable" label-width="120px">
        <el-form-item :label="`${$t('完工时间')}:`"
        :rules="[{ required: true, message: $t('不能为空')}]">
          <el-date-picker v-model="completeForm.complete_time" clearable type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss" :placeholder="$t('选择日期时间')" :default-time="default_Time">
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="`${$t('错误码')}:`">
            <el-select v-model="completeForm.fault_type" multiple clearable :placeholder="$t('此项为选填')">
                <el-option
                  v-for="item in warningList"
                  :key="item.warning_code"
                  :label="item.warning_info"
                  :value="item.warning_code">
                </el-option>
              </el-select>
          </el-input>
        </el-form-item>
       <el-form-item :label="`${$t('完成情况')}:`" :rules="[{ required: true, message: $t('不能为空')}]">
          <el-input type="textarea" :rows="5" maxlength="300" show-word-limit :placeholder="$t('请填写完成情况')"
            v-model="completeForm.complete_content">
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="完工详情说明:" :rules="[{ required: true, message: '内容不能为空'}]">
          <el-input type="textarea" :rows="5" maxlength="300" show-word-limit placeholder="请填写详情说明(选填)"
            v-model="completeForm.complete_details">
          </el-input>
        </el-form-item> -->
        <el-form-item :label="`${$t('完工图片')}:`">
          <el-upload action="http://cdshapi.orangeevcharge.com/webapi/file/uploadImg" :http-request="upLoadImg"
            list-type="picture-card" :file-list="completeForm.complete_pictures">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
              <img class="el-upload-list__item-thumbnail" :src="file.url">
              <span class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="iscomplete = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="subComplete">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!--  -->
  </div>
</template>

<script>
import mixin from '../../utils/mixin.js'
export default {
  name: 'QueryOrder',
  mixins: [mixin],
  data () {
    return {
      addAdmin: false,
      editAdmin: false,
      dialogVisible: false,
      isDispatch: false,
      isreceive: false,
      disabled: false,
      isSign: false,
      iscomplete: false,
      dialogImageUrl: '',
      UntreatedData: [],
      search: {
        name: '',
        oc_id: '',
        service_type: '',
        status: '',
        is_deactivate: '',
        is_evaluate: ''
      },
      sreachDate: [],
      addform: {
        os_id: '',
        oc_id: '',
        oe_id: '',
        service_type: '',
        contact_number: '',
        service_content: '',
        contacts: '',
        or_id: '',
        declaration_time: '',
        receive_uid: '',
        assist_uid: '',
        imageUrl: [],
        disposition: '',
        warranty_type: ''
      },
      editform: {
        id: '',
        oc_id: '',
        oe_id: '',
        service_type: '',
        contact_number: '',
        service_content: '',
        contacts: '',
        or_id: '',
        declaration_time: '',
        assist_uid: '',
        imageUrl: [],
        disposition: '',
        warranty_type: ''
      },
      DetailForm: {
        id: '',
        oc_id: '',
        oe_id: '',
        service_type: '',
        contact_number: '',
        service_content: '',
        contacts: '',
        or_id: '',
        declaration_time: '',
        receive_uid: '',
        assist_uid: '',
        imageUrl: [],
        disposition: '',
        warranty_type: ''
      },
      Page: {
        pagesize: 10,
        page: 1,
        maxpage: 1,
        total: 2
      },
      isModify: false,
      Detail: false,
      options: [{
        value: '1',
        label: this.$t('维修')
      }, {
        value: '2',
        label: this.$t('升级')
      }, {
        value: '3',
        label: this.$t('检测')
      }, {
        value: '4',
        label: this.$t('培训')
      }, {
        value: '5',
        label: this.$t('投诉')
      },
      {
        value: '0',
        label: this.$t('其他')
      }
      ],
      statuList: [{
        value: '0',
        label: this.$t('待派工')
      }, {
        value: '1',
        label: this.$t('待接单')
      },
      {
        value: '2',
        label: this.$t('待签到')
      },
      {
        value: '3',
        label: this.$t('待完工')
      },
      {
        value: '4',
        label: this.$t('待审核')
      },
      {
        value: '5',
        label: this.$t('已完成')
      }
      ],
      ocList: [],
      regionList: [],
      eqList: [],
      RLid: [],
      ERid: [],
      deactList: [{
        value: '0',
        label: this.$t('启用')
      }, {
        value: '1',
        label: this.$t('停用')
      }],
      commentList: [{
        value: '0',
        label: this.$t('未评价')
      }, {
        value: '1',
        label: this.$t('已评价')
      }],
      disList: [{
        value: '1',
        label: this.$t('上门服务')
      },
      {
        value: '2',
        label: this.$t('远程解决')
      },
      {
        value: '3',
        label: this.$t('返厂维修')
      },
      {
        value: '4',
        label: this.$t('换货')
      },
      {
        value: '5',
        label: this.$t('直接处理完成')
      },
      {
        value: '0',
        label: this.$t('其他')
      }
      ],
      warrList: [{
        value: '1',
        label: this.$t('质保期内')
      },
      {
        value: '2',
        label: this.$t('未续保')
      },
      {
        value: '3',
        label: this.$t('按次续保')
      },
      {
        value: '4',
        label: this.$t('包年续保')
      },
      {
        value: '0',
        label: this.$t('其他')
      }
      ],
      DispatchForm: {
        id: '',
        receive_uid: '',
        assist_uid: ''
      },
      receiveForm: {
        id: '',
        remarks: '',
        subscribe_time: ''
      },
      SignForm: {
        id: '',
        remarks: ''
      },
      completeForm: {
        id: '',
        complete_time: '',
        working_hours: '',
        fault_type: [],
        complete_content: '',
        complete_pictures: [],
        complete_details: '',
        sign_time: ''
      },
      ExamineID: '',
      warningList: [],
      user_role: {},
      default_Time: String(new Date()),
      BtnList: [
        {
          status: 0,
          user_role: 'work_order/dispatch',
          value: this.$t('立即派工')
        },
        {
          status: 1,
          user_role: 'work_order/receiving',
          value: this.$t('立即接单')
        },
        {
          status: 2,
          user_role: 'work_order/sign',
          value: this.$t('立即签到')
        },
        {
          status: 3,
          user_role: 'work_order/finished',
          value: this.$t('立即完工')
        },
        {
          status: 4,
          user_role: 'work_order/toexamine',
          value: this.$t('立即审核')
        }
      ]
    }
  },
  created () {
    // this.$AMap()
    // this.getID()
    this.user_role = JSON.parse(window.localStorage.getItem('user_role'))
    if (this.$route.params.status && typeof (this.$route.params.status) !== 'object') this.status = JSON.stringify(this.$route.params.status)
    if (this.$route.params.evaluate) this.is_evaluate = this.$route.params.evaluate
    this.getUserList()
    // console.log(this.$route.params)
  },
  mounted () {},
  beforeUpdate () {
    this.ocList = this.$store.state.ocList
    this.RLid = this.$store.state.RLid
    this.ERid = this.$store.state.ERid
    this.warningList = this.$store.state.codeList
  },
  computed: {
   roleList(){
     return this.$store.state.minList
   }
  },
  methods: {
    // 打开表单
    dialogForm (item, isModify) {
      this.$store.dispatch('getAdmin')
      this.isModify = isModify

      if (isModify) {
        // console.log(item)
        this.getInfoID(item.row.oc_id)
        for (var key in item.row) {
          if (item.row[key] !== 0 && item.row[key] !== null && key != 'upload_pictures') this.editform[key] = item.row[key]
        }
        this.editform.service_type = JSON.stringify(this.editform.service_type)
        this.editform.disposition = JSON.stringify(this.editform.disposition)
        this.editform.warranty_type = JSON.stringify(item.row.warranty_type)
        this.editform.imageUrl = item.row.upload_pictures
        this.editAdmin = true
      } else {
        this.addAdmin = true
      }
    },
    // 获取客户区域列表
    getUserList (page, report) {
      var params = {}; var key
      // 如果点搜索按钮则自动跳回第一页
      if (page) this.Page.page = page
      // 如果时间为空则自动定位最近一个月
      if (this.sreachDate.length > 0 && this.sreachDate != null) {
        params.start_time = this.sreachDate[0]
        params.end_time = this.sreachDate[1]
      } else {
        var time = new Date()
        time.setTime(time.getTime() - 3600 * 1000 * 24 * 31)
        params.start_time = this.$common.formatTime(time)
        params.end_time = this.$common.formatTime(new Date())
        this.sreachDate = [params.start_time, params.end_time]
      }
      //
      for (key in this.search) {
        if (this.search[key] != null && this.search[key] != '') params[key] = this.search[key]
      }
      params.page = this.Page.page
      // 到处报表
      if (report === 1) {
        params.is_report = report
        this.$common.postExcelFile(params, 'work_order/lists')
        return
      }
      // 接口函数
      this.$axios.post('work_order/lists', params).then((res) => {
        const Data = res.data
        // console.log(Data)
        if (Data.code === 0) {
          this.UntreatedData = Data.data.data
          this.Page = {
            pagesize: Data.data.pagesize,
            page: Data.data.page,
            maxpage: Data.data.maxpage,
            total: Data.data.total
          }
        } else this.$message.warning(Data.message)
      }).catch((error) => console.log(error))
    },
    // 添加
    addMin () {
      var params = {}; var key
      for (key in this.addform) {
        if (this.addform[key] != null && this.addform[key] != 'imageUrl') params[key] = this.addform[key]
      }
      params.upload_pictures = this.addform.imageUrl
      this.$axios.post('work_order/add', params).then((res) => {
        if (res.data.code === 0) {
          this.addAdmin = false
          var that = this
          // 清空表单
          this.$common.resetField('addform', that)
          this.$message({
            type: 'success',
            message: res.data.message
          })
          this.getUserList()
        } else {
          this.$message({
            type: 'warning',
            message: res.data.message
          })
          // console.log(res)
        }
      }).catch((error) => console.log(error))
    },
    // 修改
    editMin () {
      // console.log(this.editform.or_id, this.editform.oe_id)
      var params = {}; var key
      for (key in this.editform) {
        if (this.editform[key] != null && this.editform[key] != 'imageUrl') params[key] = this.editform[key]
      }
      params.upload_pictures = this.editform.imageUrl
      this.$axios.post('work_order/edit', params).then((res) => {
        // console.log(res)
        if (res.data.code === 0) {
          this.getUserList()
          this.editAdmin = false
          var that = this
          this.$common.resetField('editform', that)
          this.$message({
            type: 'success',
            message: res.data.message
          })
        } else {
          this.$message({
            type: 'warning',
            message: res.data.message
          })
          // console.log(res)
        }
      }).catch((error) => console.log(error))
    },
    // 删除
    delRole (scope) {
      // console.log(scope)
      let that = this
      this.$MessageBox.confirm(that.$t('此操作将永久删除该工单, 是否继续?'),that.$t('提示'), {
        confirmButtonText: that.$t('确定'),
        cancelButtonText: that.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.$axios.post('work_order/delete', {
          id: scope.row.id
        }).then((res) => {
          // console.log(res)
          if (res.data.code === 0) {
            this.getUserList()
            this.$message({
              type: 'success',
              message: that.$t('删除成功!')
            })
          } else this.$message.warning(res.data.message)
        }).catch((error) => console.log(error))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: that.$t('已取消删除')
        })
      })
    },
    // 获取详情
    getDetail (scope) {
      // console.log(scope)
      this.$router.push({ name: 'orderDet', params: { id: scope.row.id } })
    },
    // pageSize 改变时会触发
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    // currentPage 改变时会触发
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.getUserList(val)
    },
    /* 获取经纬度 */
    getCode () {
      // var that = this
      // this.$AMap().then((AMap) => {
      //   // 延迟一秒保证实例出现
      //   var address = document.getElementById('address')
      //   var geocoder = new AMap.Geocoder()
      //   // console.log('geocoder', geocoder)
      //   geocoder.getLocation(address.value, function (status, result) {
      //     if (status === 'complete' && result.geocodes.length) {
      //       var lnglat = result.geocodes[0].location
      //       that.addform.lng = lnglat.lng
      //       that.addform.lat = lnglat.lat
      //     } else {
      //       // log.error('根据地址查询位置失败')
      //     }
      //   })
      // }).catch((e) => {
      //   console.log(e)
      // })
    },
    // 验证图片大小
    beforeAvatarUpload (file) {
      // const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!')
      // }
      if (!isLt2M) {
        this.$message.error(this.$t('上传头像图片大小不能超过 2MB!'))
      }
      return isLt2M
    },
    // 查看图片
    handlePictureCardPreview (file) {
      //  console.log(file)
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 删除图片
    handleRemove (file) {
      let that = this
      this.$MessageBox.confirm(that.$t('此操作将永久删除, 是否继续?'),that.$t('提示'), {
        confirmButtonText: that.$t('确定'),
        cancelButtonText: that.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.$axios.post('file/delImg', {
          filename: file.url
        }).then((res) => {
          var data = res.data
          if (data.code === 0) {
            var delEle = ''
            // /* */
            if (this.addform.imageUrl) {
              this.addform.imageUrl.forEach((item, index) => {
                if (item === file) delEle = index
              })
              if (delEle === 0) this.addform.imageUrl.splice(0, 1)
              else this.addform.imageUrl.splice(delEle, delEle)
            }
            // /* 分隔*/
            if (this.editform.imageUrl) {
              this.editform.imageUrl.forEach((item, index) => {
                if (item === file) delEle = index
              })
              if (delEle === 0) this.editform.imageUrl.splice(0, 1)
              else this.editform.imageUrl.splice(delEle, delEle)
            }
            // /* 分隔*/
            if (this.completeForm.complete_pictures) {
              this.completeForm.complete_pictures.forEach((item, index) => {
                if (item === file) delEle = index
              })
              if (delEle === 0) this.completeForm.complete_pictures.splice(0, 1)
              else this.completeForm.complete_pictures.splice(delEle, delEle)
            }
            // /* 分隔*/
          } else {
            this.$message.error(data.message)
          }
        }).catch((error) => {
          console.log(error)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: that.$t('已取消删除')
        })
      })
    },
    // 上传图片
    upLoadImg (file) {
      var FD = new FormData()
      FD.append('filename', file.file, file.file.name)
      this.$axios.post('file/uploadImg', FD).then((res) => {
        // console.log(res)
        var data = res.data
        if (data.code === 0) {
          file.file.url = data.data.url
          if (this.addAdmin) {
            this.addform.imageUrl.push(file.file)
          } else if (this.editAdmin) {
            this.editform.imageUrl.push(file.file)
          } else if (this.iscomplete) {
            this.completeForm.complete_pictures.push(file.file)
          }
        } else { this.$message.warning(data.message) }
      }).catch(error => console.log(error))
    },
    // 做客户数据ID的三级联动
    getInfoID (ocid) {
      this.regionList = []
      this.RLid.forEach(item => {
        if (item.oc_id === ocid) this.regionList.push(item)
      })

      if (this.addAdmin) {
        this.addform.or_id = this.regionList[0].id
        this.getOEid(this.addform.or_id)
      } else {
        this.editform.or_id = this.regionList[0].id
        this.getOEid(this.editform.or_id)
      }
    },
    // 获取设备ID
    getOEid (orid) {
      // console.log('orid', orid)
      this.eqList = []
      if (typeof (orid) === 'object') { orid = orid.id }
      this.ERid.forEach(item1 => {
        if (item1.or_id === orid) this.eqList.push(item1)
      })
      if (this.addAdmin) {
        this.addform.oe_id = this.eqList[0]
      }
      // if (this.editAdmin) {
      //   this.editform.oe_id = this.eqList[0]
      // }
    },
    // 关闭修改弹窗
    closeEdit () {
      this.editAdmin = false
      this.editform.imageUrl = []
    },
    // 打开工作流程弹窗
    process (scope) {
      // console.log(scope)
      this.$store.dispatch('getAdmin')
      // 打开派工窗口
      if (scope.row.status === 0) {
        this.isDispatch = true
        this.DispatchForm.id = scope.row.id
      }
      // 打开接单窗口
      if (scope.row.status === 1) {
        this.isreceive = true
        this.receiveForm.id = scope.row.id
        // this.receiveForm.subscribe_time = scope.row.declaration_time
      }
      // 打开签到窗口
      if (scope.row.status === 2) {
        this.isSign = true
        this.SignForm.id = scope.row.id
      }
      // 打开完工窗口
      if (scope.row.status === 3) {
        this.iscomplete = true
        this.completeForm.id = scope.row.id
        this.completeForm.sign_time = scope.row.sign_time
      }
      // 打开审核窗口
      if (scope.row.status === 4) {
        this.ExamineID = scope.row.id
        this.examine()
      }
    },
    // 提交派工表单
    subDispatch () {
      this.$axios.post('work_order/dispatch', {
        id: this.DispatchForm.id,
        receive_uid: this.DispatchForm.receive_uid,
        assist_uid: this.DispatchForm.assist_uid
      }).then((res) => {
        // console.log(res)
        var data = res.data
        if (data.code === 0) {
          this.isDispatch = false
          this.DispatchForm.receive_uid = ''
          this.DispatchForm.assist_uid = ''
          this.getUserList()
          this.$message.success(data.message)
        } else {
          this.$message.warning(data.message)
        }
      }).catch((err) => console.log(err))
    },
    // 提交接单表单
    subReceive () {
      this.$axios.post('work_order/receiving', {
        id: this.receiveForm.id,
        order_taking_remarks: this.receiveForm.remarks,
        subscribe_start_time: this.receiveForm.subscribe_time
      }).then((res) => {
        // console.log(res)
        var data = res.data
        if (data.code === 0) {
          this.isreceive = false
          this.$message.success(data.message)
          this.getUserList()
          this.receiveForm.remarks = ''
        } else {
          this.$message.warning(data.message)
        }
      }).catch((err) => console.log(err))
    },
    // 提交签到
    subSign () {
      this.$axios.post('work_order/sign', {
        id: this.SignForm.id,
        sign_remark: this.SignForm.remarks
      }).then((res) => {
        // console.log(res)
        var data = res.data
        if (data.code === 0) {
          this.isSign = false
          this.$message.success(data.message)
          this.getUserList()
          this.SignForm.remarks = ''
        } else {
          this.$message.warning(data.message)
        }
      }).catch((err) => console.log(err))
    },
    // 提交完成工单表单
    subComplete () {
      if (this.completeForm.complete_time !== '' && this.completeForm.sign_time !== '') {
        var completeTime = new Date(this.completeForm.complete_time).getTime()
        var signTime = new Date(this.completeForm.sign_time).getTime()
        var hours = (completeTime - signTime) / (1000 * 60 * 24)
        this.completeForm.working_hours = Math.round(hours)
        if (this.completeForm.fault_type) this.completeForm.fault_type = this.completeForm.fault_type.join(',')
        this.$axios.post('work_order/finished', {
          id: this.completeForm.id,
          complete_time: this.completeForm.complete_time,
          complete_working_hours: this.completeForm.working_hours,
          fault_type: this.completeForm.fault_type,
          complete_content: this.completeForm.complete_content,
          complete_pictures: this.completeForm.complete_pictures
          // complete_details: this.completeForm.complete_details
        }).then((res) => {
          var data = res.data
          if (data.code === 0) {
            this.iscomplete = false
            this.$message.success(data.message)
            this.getUserList()
            this.completeForm.remarks = ''
          } else {
            this.completeForm.fault_type = []
            this.$message.warning(data.message)
          }
        }).catch((err) => console.log(err))
      } else {
        this.$message.warning(this.$t('完成时间或签到时间不得为空'))
      }
    },
    // 审核
    examine () {
      let that = this
      this.$MessageBox.confirm(that.$t('确定将此工单通过审核吗'),that.$t('提示'), {
        confirmButtonText: that.$t('确定'),
        cancelButtonText: that.$t('取消'),
        type: 'warning'
      }).then(()=>{
        this.$axios.post('work_order/toexamine', {
          id: this.ExamineID
        }).then((res) => {
          // console.log(res)
          var data = res.data
          if (data.code === 0) {
            this.$message.success(data.message)
            this.getUserList()
            this.ExamineID = ''
          } else {
            this.$message.warning(data.message)
          }
        }).catch((err) => console.log(err))
      }).catch(()=>{
        this.$message({
          type: 'info',
          message: that.$t('已取消操作')
        })
      })

    }/*  */
  }
}
</script>

<style scoped>
  .Untreated_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top_title {
    color: #ff9252;
  }

  ::v-deep .el-select,
  ::v-deep .el-date-editor {
    width: 100% !important;
  }

  .el-input.is-disabled::v-deep .el-input__inner,
  .el-select::v-deep .el-input.is-disabled .el-input__inner {
    color: #575757;
  }

  .search {
    margin: 15px 0;
  }

  .search .el-col {
    margin: 5px 0;
  }

  ::v-deep .el-upload-dragger {
    width: 200px;
    height: 200px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 200px;
    height: 200px;
    line-height: 200px;
    text-align: center;
  }

  .avatar {
    width: 200px;
    height: 200px;
    display: block;
  }
</style>
